/* Global Font Import */
@font-face {
    font-family: 'RadioCanada';
    src: url('../assets/fonts/RadioCanada-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900; /* Supports variable weights */
  font-style: normal;
}

body {
  font-family: "Montserrat", sans-serif;
}


@font-face {
    font-family: 'RadioCanada';
    src: url('../assets/fonts/RadioCanada-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Apply Global Font to Body */
body {
    font-family: 'RadioCanada', Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Responsive Utilities */

/* Mobile adjustments (max 768px) */
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
  
  .mobile-full-width {
    width: 100%;
  }
  
  .mobile-center {
    text-align: center;
  }
}

/* Desktop adjustments (769px and above) */
@media (min-width: 769px) {
  .hide-on-desktop {
    display: none;
  }

  .desktop-padding {
    padding: 20px;
  }
}


@media (max-width: 768px) {
  .profile-title-mobile {
    display: none;
  }
}


/* Custom scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;  /* Thin scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;  /* Invisible track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 0.6);  /* Semi-transparent grey */
  border-radius: 10px;  /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.8);  /* Darker on hover */
}

/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(150, 150, 150, 0.6) transparent;
}
