/* Import Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

/* Font Styles */
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

/* Gray Line Breaker */
.breaker-gray {
  width: 80px;
  height: 2px;
  background: linear-gradient(90deg, #555, #888);
  margin: 0 auto;
  border-radius: 2px;
  opacity: 0.8;
}

/* Hover Glow Effect */
.hover\:prelaunch-hover-glow:hover {
  box-shadow: 0 0 20px #51C242, 0 0 40px #51C242;
  transition: box-shadow 0.3s ease-in-out;
}

/* Click Glow Effect */
.active\:prelaunch-click-glow:active {
  box-shadow: 0 0 20px #51C242, 0 0 40px #51C242;
  transition: box-shadow 0.1s ease-in-out;
}

/* The Goal Room Experience Section */
.goal-room-experience {
  position: relative;
  padding: 50px 20px;
  color: white;
  z-index: 1;
  overflow: hidden;
}

.goal-room-experience:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-image: url('/src/assets/pics/backgroundimg.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: blur(3px) brightness(0.5);
  z-index: -2;
}

.goal-room-experience .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

/* Content Above Background */
.goal-room-experience h2,
.goal-room-experience .grid {
  position: relative;
  z-index: 2;
}

/* Cards */
.goal-room-experience .grid > div {
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(135deg, rgba(34, 34, 34, 0.9), rgba(51, 51, 51, 0.9));
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.goal-room-experience .grid > div:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 255, 0, 0.3);
}

.goal-room-experience .grid h3 {
  font-size: 1.5rem;
  color: #51C242;
  font-weight: 600;
}

.goal-room-experience .grid p {
  font-size: 1rem;
  color: #d3d3d3;
  margin-top: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .breaker-gray {
    width: 60px;
  }

  .goal-room-experience {
    padding: 40px 15px;
  }

  .goal-room-experience .grid h3 {
    font-size: 1.25rem;
  }

  .goal-room-experience .grid p {
    font-size: 0.9rem;
  }
}

/* --- INTRO ANIMATION --- */
.intro-logo-animation {
  animation: logo-appear 3s ease-in-out forwards;
  width: 50vw; /* Default size */
  height: auto;
}

.intro-page {
  display: flex;
  flex-direction: column; /* Align elements vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  z-index: 50;
  background-color: #181818;
  overflow: hidden; /* Prevent scrollbars */
}

/* Keyframes for Blinking Twice */
@keyframes blink-twice {
  0%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.intro-logo-blink-animation {
  animation: blink-twice 2s ease-in-out 1;
  width: 50vw;
  height: auto;
}

/* --- PAGE TRANSITIONS --- */
.transition-opacity {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.translate-y-10 {
  transform: translateY(10px);
}

.translate-y-0 {
  transform: translateY(0);
}

.scale-125 {
  transform: scale(1.25);
}

.scale-100 {
  transform: scale(1);
}

.transition-transform {
  transition: transform 1s ease-in-out;
}

/* Notify Me Section */
.notify-me-section {
  max-width: 600px;
  margin: 60px auto 0; /* Adjusted for equal spacing from top and bottom */
  background-color: rgba(24, 24, 24, 0.9); /* Updated for consistency */
  border-radius: 12px;
  padding: 20px 30px;
}

.notify-me-section h3 {
  font-size: 1.75rem;
  color: white;
}

.notify-me-section p {
  font-size: 1rem;
  color: #d3d3d3;
  line-height: 1.5;
}

.notify-me-section input[type="email"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  font-size: 1rem;
  border: 2px solid #444;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.notify-me-section input[type="email"]:focus {
  border-color: #51C242;
}

.notify-me-section button {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: 600;
  background-color: #51C242;
  color: white;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.notify-me-section button:hover {
  background-color: #45a839;
}

/* Footer */
footer {
  text-align: center;
  margin-top: 50px;
  padding: 20px 0;
  background: #181818;
  color: #777;
  font-size: 0.875rem;
}
